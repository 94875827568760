<template>
	<div>
		<div v-if="!carouselMode" class="preview-images-thumb--imageshowroom--component-wrapper">
			<div v-for="(img, i) in images" :key="i" class="asset-preview-picture">
				<div class="rich-attachment" @click="showPicture(i)">
					<img :src="img.thumb" :alt="img.xid" />
				</div>
				<div class="veil" />

				<b-dropdown size="lg" variant="link" toggle-class="text-decoration-none" no-caret>
					<template #button-content>
						<i class="icon-arrow-down" />
					</template>
					<b-dropdown-item v-if="!hideCropOption" @click="handleCropPicture(img.xid, i)">
						<span>
							{{ FormMSG(9992323, 'Crop') }}
						</span>
					</b-dropdown-item>
					<b-dropdown-item v-if="!hideDeleteOption" @click="handleDeletePicture(img.xid)">
						<span class="text-danger">
							{{ FormMSG(2323, 'Delete picture') }}
						</span>
					</b-dropdown-item>
				</b-dropdown>
			</div>
			<span class="add-attachment-btn" @click="clickAddPicture">
				<b-spinner v-if="loading" variant="light" small />
				<i v-else class="icon-plus" />
			</span>
		</div>
		<div v-if="carouselMode">
			<b-row v-if="$screen.width > 576">
				<b-col>
					<Carousel :value="carouselImages" :numVisible="isNumVisible" :numScroll="isNumScroll" :responsiveOptions="isReponsiveOptions">
						<template #item="slotProps">
							<div v-if="slotProps.data.isFirst" class="add-img mt-2" @click="clickAddPicture">
								<div class="center-content">
									<button type="button" class="btn-transparent">
										<component :is="getLucideIcon('PlusCircle')" :size="20" color="#00A09C" />
									</button>
								</div>
							</div>
							<div v-else class="border-1 surface-border m-2 border-round">
								<div
									@mouseenter="handleMouseEnter(slotProps.data, slotProps.index - 1)"
									@mouseleave="handleMouseLeave(slotProps.data, slotProps.index - 1)"
								>
									<div class="d-flex flex-column justify-content-center position-absolute ml-1 h-75">
										<div
											class="bg-primary text-white mb-3"
											style="width: 93px"
											v-show="slotProps.data.hoverAction"
											v-b-tooltip.hover
											:title="filenames[slotProps.index - 1].name"
										>
											{{ subSentence(filenames[slotProps.index - 1].name) }}
										</div>
										<div
											:class="{
												'd-flex align-items-center justify-content-center': slotProps.data.hoverAction,
												'd-none': !slotProps.data.hoverAction
											}"
										>
											<button type="button" class="btn-transparent" @click="showPicture(slotProps.index - 1)">
												<component :is="getLucideIcon('Eye')" :size="22" :stroke-width="1.5" color="#00A09C" />
											</button>
											<button
												type="button"
												class="btn-transparent text-color-burning-tomato"
												@click="handleDeletePicture(slotProps.data.xid)"
											>
												<component :is="getLucideIcon('Trash2')" :size="22" :stroke-width="1.5" />
											</button>
										</div>
									</div>
									<img :src="slotProps.data.thumb" :alt="slotProps.data.xid" class="location-slide-img" />
								</div>
							</div>
						</template>
					</Carousel>
				</b-col>
			</b-row>
			<b-row v-if="$screen.width <= 576 && carouselImages.length > 0">
				<b-col cols="12" class="mb-3">
					<b-button block size="md" variant="outline-primary" @click="showPicture(0)">
						<component :is="getLucideIcon('FileImage')" :size="16" />
						{{ `${FormMSG(90, 'View')} ${carouselImages.length} ${FormMSG(91, 'pictures')}` }}
					</b-button>
				</b-col>
			</b-row>
			<b-row v-if="$screen.width <= 576">
				<b-col cols="12">
					<b-button block size="md" variant="outline-success" @click="clickAddPicture">
						{{ FormMSG(79, 'Add pictures') }}
					</b-button>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import mapProps from '@/shared/vuePropsMapper';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { Slide } from 'vue-carousel';
import Carousel from 'primevue/carousel';
import { getImageChild } from '@/cruds/image-child.crud';

export default {
	name: 'PreviewImagesThumbComponent',
	mixins: [languageMessages, globalMixin],
	components: {
		Carousel,
		Slide
	},
	props: {
		...mapProps(['hideCropOption', 'hideDeleteOption', 'loading', 'carouselMode'], {
			type: Boolean,
			required: false,
			default: false
		}),
		images: {
			type: Array,
			required: true,
			default: () => []
		}
	},
	data() {
		return {
			isPwaCaptureLoading: false,
			carouselImages: [],
			filenames: [],
			numVisible: 0,
			responsiveOptions: []
		};
	},
	watch: {
		images: {
			handler(newVal) {
				let images = newVal;
				this.carouselImages = [
					{
						hoverAction: false,
						src: null,
						thumb: null,
						xid: null,
						isFirst: true
					},
					...images.map((option) => {
						this.filenames.push({
							name: option.xid
						});

						return {
							...option,
							hoverAction: false,
							isFirst: false
						};
					})
				];
				this.isNumVisible;
				this.isNumScroll;
				this.isReponsiveOptions;
			},
			immediate: true,
			deep: true
		}
	},
	computed: {
		isNumVisible() {
			const size = window.innerWidth;
			if (size > 1700) {
				return 4;
			} else if (size < 1700 && size > 1500) {
				return 3;
			} else if (size < 1500 && size > 900) {
				return 2;
			}
			return 1;
		},
		isNumScroll() {
			return this.isNumVisible;
		},
		isReponsiveOptions() {
			const _responsiveOptions = [
				{
					breakpoint: `${window.innerWidth}px`,
					numVisible: this.isNumVisible,
					numScroll: this.isNumVisible
				}
			];
			return _responsiveOptions;
		}
	},
	methods: {
		subSentence(value) {
			const ext = value.split('.')[1];
			return value.substr(0, 5) + '... .' + ext;
		},
		handleMouseLeave(image, index) {
			image.hoverAction = false;
		},
		async handleMouseEnter(image, index) {
			if (this.filenames[index].name === '') {
				const imageChild = await getImageChild(image.xid.split('.')[0]);
				this.filenames[index].name = imageChild.originalFileName;
			}
			image.hoverAction = true;
		},
		/**
		 * @param {Number} index
		 */
		showPicture(index) {
			this.$emit('show-picture', index);
		},

		/**
		 * @param {String} img
		 * @param {Number} index
		 */
		handleCropPicture(img, index) {
			this.$emit('crop-picture', { xid, index });
		},

		/**
		 * @param {String} xid
		 */
		handleDeletePicture(xid) {
			this.$emit('delete-picture', xid);
		},

		clickAddPicture() {
			this.$emit('add-pic-click');
		}
	}
};
</script>

<style lang="scss" scoped>
.preview-images-thumb--imageshowroom--component-wrapper {
	width: 100%;
	align-items: center;
	display: flex;
	flex-wrap: wrap;

	.asset-preview-picture .rich-attachment,
	span.add-attachment-btn {
		background: #252628;
		align-items: center;
		display: flex;
		justify-content: center;
		cursor: pointer;
		border-radius: 8px;
		border: 1px solid #424244;
	}

	.asset-preview-picture {
		margin: 0 8px 8px 0;
		position: relative;

		.rich-attachment,
		.dropdown {
			transition: opacity ease-in-out 300ms;
		}

		.rich-attachment {
			max-height: 64px;
			box-sizing: border-box;
			outline: 0;
			overflow: hidden;
			position: relative;
			cursor: pointer;
			& > img {
				max-height: 64px;
				display: block;
			}
		}

		& > .veil,
		& > .dropdown {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			opacity: 0;
		}
		& > .veil {
			pointer-events: none;
			z-index: 1;
			height: 100%;
			width: 100%;
			border-radius: 8px;
			background-color: rgba(0, 0, 0, 0.4);
		}

		& > .dropdown {
			z-index: 12;
			right: 15px;
			left: auto;
			background-color: rgba(255, 255, 255, 0.6);
			border-radius: 8px;
			height: 24px;
			width: 24px;
			margin: auto;
		}

		&:hover {
			& > .veil,
			& > .dropdown {
				opacity: 1;
			}
		}
	}

	span.add-attachment-btn {
		height: 64px;
		margin-bottom: 8px;
		width: 64px;
		border-style: dashed;
		i {
			color: #fff;
			font-size: 20px;
		}
	}
}
</style>
