<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isModalOpen"
		ok-variant="success"
		cancel-variant="light"
		size="lg"
		hide-header-close
		no-close-on-backdrop
		no-close-on-esc
		:ok-title="FormMSG(29801, 'Save')"
		:cancel-title="FormMSG(212302, 'Cancel')"
		:title="FormMSG(26700, 'Picture capture')"
		:class="rendComponentClass"
		:ok-disabled="isLoading"
		:cancel-disabled="isLoading"
		@ok.prevent="saveCapturedPics"
		@cancel="handleCloseModal('cancel')"
		@close="handleCloseModal('close')"
		@hidden="handleCloseModal('hidden')"
	>
		<div ref="containerCaptureModal">
			<b-container fluid>
				<b-row align-v="center" align-h="between" v-if="$screen.width > 576">
					<b-col cols="5">
						<span>{{ FormMSG(5, 'Select an image from your computer') }}</span>
					</b-col>
					<b-col cols="auto">
						<separator :label="FormMSG(1, 'or')" />
					</b-col>
					<b-col cols="5">
						<span>{{ FormMSG(6, 'Take picture with your webcam') }}</span>
					</b-col>
				</b-row>
				<b-row align-h="between">
					<b-col md="5" sm="12">
						<DesktopImgUpload
							:acceptDocx="false"
							:acceptExlx="false"
							:acceptPptx="false"
							:manager="manager"
							@change="handleDesktopImageSelected"
							@change-filename="handleChangeFileName"
						/>
					</b-col>
					<b-col cols="12" v-if="$screen.width <= 576" class="mb-2">
						<separator :label="FormMSG(1, 'or')" />
					</b-col>
					<b-col md="5" sm="12" :class="$screen.width <= 576 ? 'mb-3' : ''">
						<b-button @click="openWebCamCaptureModal" block size="sm">
							{{ FormMSG(342234, 'Use webcam') }}
						</b-button>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<CapturedImgsList
							:manager="manager"
							:for-new-expense="forNewExpense"
							:for-multiple-doc="forMultipleDoc"
							:for-splitting-tva="forSplittingTva"
					/></b-col>
				</b-row>
			</b-container>
			<b-modal
				header-class="header-class-modal-doc-package"
				ref="modal"
				v-model="isWebCamCaptureModalOpen"
				ok-variant="success"
				size="lg"
				:title="FormMSG(200, 'Picture capture')"
				:ok-title="FormMSG(201, 'Save')"
				:cancel-title="FormMSG(202, 'Cancel')"
				@ok="saveWebCamCapture"
				@cancel="isWebCamCaptureModalOpen = false"
				@hidden="isWebCamCaptureModalOpen = false"
			>
				<CaptureWebCam :manager="manager" @change="handleCaptureChange" />
			</b-modal>
		</div>
		<template #modal-ok>
			<div>
				<b-spinner v-if="isLoading" small />
				{{ FormMSG(29801, 'Save') }}
			</div>
		</template>
	</b-modal>
</template>

<script>
import { isNil, isBase64, makeID, generateSecureId } from '@/shared/utils';
import { isFileExtImage } from '@/shared/helpers';
import { store } from '@/store';
import { b64toFile, getExtensionFromBase64, base64ToBlob, reScalingImgWithCanvas, provideFileDataToUpload } from '@/components/Packages/Captures/capture.utils';
import DesktopImgUpload from '@/components/Packages/Captures/components/DesktopImg';
import CaptureWebCam from '@/components/Packages/Captures/components/CaptureWebCam';
import CapturedImgsList from '@/components/Packages/Captures/components/CapturedImgsList';
import languageMessages from '@/mixins/languageMessages';
import modalMixin from '@/mixins/modal.mixin';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'PackagesCaptureComponentsCaptureModal',
	components: { DesktopImgUpload, CaptureWebCam, CapturedImgsList },
	mixins: [modalMixin, languageMessages, globalMixin],
	props: {
		isUsed: {
			type: String,
			required: false
		},
		manager: {
			type: Object,
			required: true,
			default: () => {}
		},
		/**
		 * function for generating a key for each upload file
		 * /!\/!\/!\ this function should be a Promise /!\/!\/!\
		 */
		generateDynamicParentsIdPerDoc: {
			type: Function,
			required: false,
			default: null
		},
		forNewExpense: {
			type: Boolean,
			default: false,
			required: false
		},
		forMultipleDoc: {
			type: Boolean,
			default: false,
			required: false
		},
		forSplittingTva: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			capturedImageWebCam: null,
			isLoading: false,
			uploadFileCount: 1,
			fileNames: []
		};
	},
	computed: {
		/**
		 * To show the checkbox on upload images or not.
		 * @return {Boolean}
		 */
		isPicturesToSaveForSingleExpense() {
			return (
				store.getCurrentProjectConfig().useOcr &&
				['My Expenses', 'expensesEntryCrew'].includes(this.$route.name) &&
				!this.forMultipleDoc &&
				!this.forSplittingTva
			);
		},
		capturedPicsList() {
			return this.manager.states.capturedPicsList;
		},
		/**
		 * @return {Object}
		 */
		rendComponentClass() {
			return {
				'has-multiple-images': this.manager.states.multiple
			};
		},
		isWebCamCaptureModalOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isWebCamCaptureModalOpen;
			},
			/**
			 * @param {Boolean}
			 */
			set(status) {
				this.manager.dispatch('toggleWebCamCaptureModal', status);
			}
		},
		/**
		 * @return {Object }
		 */
		captureOptions() {
			return this.manager.states.options;
		}
	},
	methods: {
		handleChangeFileName(payload) {
			this.fileNames = payload || [];
		},
		/**
		 * @param {String} eventType
		 */
		handleCloseModal(eventType) {
			if (eventType === 'cancel') this.manager.dispatch('clearCapturedPicsList');
			this.$emit(eventType, true);

			this.images = [];
			this.picturesToKeep = [];
			this.savedImages = [];
			this.fileNames = [];
			this.manager.dispatch('toggleCaptureModal', false);

			if (this.isUsed === 'waste') {
				this.$emit('handleCloseModalFile', false);
			}

			this.isLoading = false;
		},
		/**
		 * @param {Array} images
		 * @param {Number} id
		 */
		async loopImages(images) {
			if (this.manager.states.editPicsList.length > 0) {
				this.manager.dispatch('initImgsListPayload');
			}
			for (let i = 0; i < images.length; i++) {
				const img = images[i];
				if (!isBase64(img)) {
					this.manager.dispatch('setImgsListPayload', img);
				} else {
					await this.saveImage(img, i);
				}
			}
		},
		async saveCapturedPics() {
			this.isLoading = true;
			// console.log({ images: this.capturedPicsList });
			await this.loopImages(this.capturedPicsList);
			this.manager.dispatch('clearCapturedPicsList');
			// console.log(this.manager.states.imgsListPayload);
			if (this.isPicturesToSaveForSingleExpense) {
				this.$emit('change', { images: this.manager.states.imgsListPayload, imagesForOcr: this.manager.states.picsListForOcr });
			} else {
				this.$emit('change', this.manager.states.imgsListPayload);
			}

			// this.manager.dispatch('clearImgsList');

			// if (this.isUsed === 'waste') {
			// 	this.$emit('change', this.manager.states.imgsListPayload);
			// }

			this.manager.dispatch('clearImgsList');
			this.manager.dispatch('clearPicsForOcr'); // to clear all pictures list saved for ocr.
			this.handleCloseModal('close');
		},
		async saveImage(imgData, index) {
			if (!isBase64(imgData)) return;

			const base64Data = imgData.split(';base64,');
			// const ext = base64Data[0].replace('data:image/', '')
			const ext = getExtensionFromBase64(imgData);
			const file = b64toFile(base64Data[1], this.fileNames[index], ext);

			let formData = new FormData();
			const fileData = await provideFileDataToUpload(file);
			formData.append('uploadimage', fileData.file);

			// if (isFileExtImage(ext)) {
			// 	try {
			// 		// const compressedFile = await getCompressedImage(imgData);
			// 		const scaledFile = await reScalingImgWithCanvas(imgData);
			// 		formData.set('uploadimage', scaledFile);
			// 	} catch (err) {
			// 		console.error({ COMPRESSION_ERR: err.message });
			// 	}
			// }

			const { parentType, parentSubType, parentId } = this.captureOptions;
			// console.log({ captureOptions: this.captureOptions });
			if (!isNil(parentType)) formData.append('parentType', parentType);
			if (!isNil(parentSubType)) formData.append('parentSubType', parentSubType);

			const generatedParentId =
				this.uploadFileCount > 1 && !isNil(this.generateDynamicParentsIdPerDoc) ? await this.generateDynamicParentsIdPerDoc() : parentId;
			if (!isNil(parentId)) formData.append('parentId', generatedParentId);

			formData.append('fileName', fileData.name);

			try {
				const urlStr = process.env.VUE_APP_GQL + '/upload';
				const savedImg = await this.$axios.$post(urlStr, formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});
				this.uploadFileCount = this.uploadFileCount + 1;
				// fill the image list for OCR if the upload action is in the expenses.
				if (this.isPicturesToSaveForSingleExpense) this.manager.dispatch('patchPicsListForOcr', { toMatch: imgData, toReplace: savedImg });
				this.manager.dispatch('setImgsListPayload', savedImg);
			} catch (e) {
				console.error({ e });
				this.manager.emit('upload-image:error', e);
			}
		},
		openWebCamCaptureModal() {
			this.isWebCamCaptureModalOpen = true;
		},
		handleDesktopImageSelected(img) {
			this.manager.dispatch('addCapturedPicsList', img);
		},
		saveWebCamCapture() {
			this.manager.dispatch('addCapturedPicsList', this.capturedImageWebCam);
		},
		handleCaptureChange(capture) {
			// console.log({ handleCaptureChange: capture });
			this.capturedImageWebCam = capture;
			// this.manager.dispatch('addCapturedPicsList', capture)
		},
		generateFilename(extensionFile) {
			return generateSecureId(`filename-id-${makeID(8)}`).substring(0, 24) + '.' + extensionFile;
		}
	}
};
</script>
