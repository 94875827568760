<template>
	<div>
		<div class="bg-light p-2 m-2 rounded" v-if="processedCapturedPicsList.length > 0 && showCheckPicture">
			<span>
				<component :is="getLucideIcon('CheckSquare')" :size="16" :stroke-width="2" class="mx-2" />
				{{ FormMSG(5, 'Please, verify and check only the files you want to pass in the OCR.') }}
			</span>
		</div>
		<b-table
			responsive="sm"
			:fields="fields"
			:items="processedCapturedPicsList"
			:head-variant="hv"
			:hover="true"
			:caption-top="true"
			:sticky-header="$screen.width <= 576 ? '200px' : '500px'"
			style="text-align: left"
			bordered
			small
			:empty-text="FormMSG(3, 'No files imported Yet')"
			show-empty
		>
			<template #head(actionSelected)="">
				<b-form-checkbox size="lg" v-if="processedCapturedPicsList.length" v-model="selectAll" @change="handleInputSelectAll" />
			</template>
			<template #cell(actionSelected)="data">
				<b-form-checkbox v-model="data.item.ocrUseState" size="lg" />
			</template>
			<template #cell(data)="data">
				<div class="captured-item">
					<div class="preview-container">
						<img v-if="data.item.fileType && data.item.fileType.startsWith('image')" :src="data.item.data" />
						<div v-else class="file-icon">
							<!-- Display a file icon based on the file type -->
							<component :is="getLucideIcon('FileText')" :size="20" :stroke-width="2" />
						</div>
					</div>
					<div class="actions">
						<b-button
							v-show="isCroppable(data.item.data)"
							variant="link"
							class="text-dark"
							size="sm"
							@click="setImageForCrop(data.item.data, data.index)"
						>
							<component :is="getLucideIcon('Crop')" :size="20" :stroke-width="2" />
							<span class="ml-2" v-if="$screen.width > 576">{{ FormMSG(6, 'Crop') }}</span>
						</b-button>
						<b-button size="sm" variant="link" class="text-danger" @click="removeCapturedPic(data.index)">
							<component :is="getLucideIcon('Trash')" :size="20" :stroke-width="2" />
							<span class="ml-2" v-if="$screen.width > 576">{{ FormMSG(7, 'Remove') }}</span>
						</b-button>
					</div>
				</div>
			</template>
		</b-table>
	</div>
</template>

<script>
import { store } from '@/store';
import ScissorsIconSVG from '@/components/icons/scissors';
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';

export default {
	name: 'CapturedImgsListComponent',
	mixins: [languageMessages, globalMixin],
	components: { ScissorsIconSVG },
	props: {
		manager: {
			type: Object,
			required: true,
			default: () => {}
		},
		forNewExpense: {
			type: Boolean,
			default: false,
			required: false
		},
		forMultipleDoc: {
			type: Boolean,
			default: false,
			required: false
		},
		forSplittingTva: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			hv: 'dark',
			processedCapturedPicsList: [],
			selectAll: false
		};
	},
	computed: {
		/**
		 * @return {Array}
		 */
		capturedPicsList() {
			return this.manager.states.capturedPicsList;
		},
		showCheckPicture() {
			return (
				store.getCurrentProjectConfig().useOcr &&
				['My Expenses', 'expensesEntryCrew'].includes(this.$route.name) &&
				!this.forMultipleDoc &&
				!this.forSplittingTva
			);
		},
		fields() {
			const actionSelectField = {
				key: 'actionSelected',
				label: '',
				class: 'text-center align-middle',
				sortable: false,
				thStyle: { width: '70px' }
			};
			const fields = [
				{
					key: 'data',
					label: this.FormMSG(4, 'Your files'),
					sortable: false
				}
			];
			if (this.showCheckPicture) fields.unshift(actionSelectField);
			return fields;
		}
	},
	watch: {
		capturedPicsList: {
			handler(newValue) {
				if (!_.isNil(newValue)) {
					this.processPicsList();
				}
			},
			deep: true,
			immediate: true
		},
		processedCapturedPicsList: {
			handler(newValue) {
				const allPicsOcrStateIsTrue = newValue.every((x) => x.ocrUseState);
				this.selectAll = allPicsOcrStateIsTrue;

				if (this.showCheckPicture) this.manager.dispatch('setPicsListForOcr', this.providePicsListForOcr());
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.processPicsList();
	},
	methods: {
		/**
		 * @param {String} file
		 * @return {Boolean}
		 */
		isCroppable(file) {
			const [type] = file.split('/');
			return type == 'data:image';
		},

		processPicsList() {
			this.processedCapturedPicsList = this.capturedPicsList.map((pic, index) => {
				const [typeInfo] = pic.match(/^data:([^;]*);/);
				const fileType = typeInfo ? typeInfo.replace('data:', '').replace(';', '') : null;
				const matchingItem = this.processedCapturedPicsList.find((oldPic) => oldPic.data === pic);
				const isFistElement = index === 0 && this.forNewExpense;
				return {
					fileType,
					data: pic,
					ocrUseState: matchingItem ? matchingItem.ocrUseState : isFistElement
				};
			});
		},

		/**
		 * @param {String} index
		 */
		removeCapturedPic(index) {
			this.manager.dispatch('removeCapturedPicsListItem', index);
		},

		/**
		 * @param {String} img
		 * @param {String} index
		 */
		setImageForCrop(img, index) {
			this.manager.dispatch('setImageForCrop', {
				img,
				index,
				type: 'cropBeforeUpload'
			});
			store.state.imageToCrop = img;
			this.manager.dispatch('toggleCropCaptureModalOpen', true);
		},
		handleInputSelectAll() {
			this.processedCapturedPicsList = this.processedCapturedPicsList.map((v) => {
				return {
					...v,
					ocrUseState: this.selectAll
				};
			});
		},
		providePicsListForOcr() {
			return this.processedCapturedPicsList
				.filter((item) => item.ocrUseState === true)
				.map((v) => {
					return v.data;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
.captured-item {
	width: 100%;
	display: flex;
	height: 64px;
	justify-content: space-between;
	.preview-container {
		border-radius: 8px;
		overflow: hidden;
		max-height: 64px;
		max-width: 64px;

		img {
			object-fit: contain;
			width: 100%;
			height: 100%;
		}
	}
	.actions {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		button:not(:first-child) {
			margin-left: 15px;
			margin-right: 15px;
		}
	}
}
</style>
