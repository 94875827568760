var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.processedCapturedPicsList.length > 0 && _vm.showCheckPicture
        ? _c("div", { staticClass: "bg-light p-2 m-2 rounded" }, [
            _c(
              "span",
              [
                _c(_vm.getLucideIcon("CheckSquare"), {
                  tag: "component",
                  staticClass: "mx-2",
                  attrs: { size: 16, "stroke-width": 2 },
                }),
                _vm._v(
                  "\n\t\t\t" +
                    _vm._s(
                      _vm.FormMSG(
                        5,
                        "Please, verify and check only the files you want to pass in the OCR."
                      )
                    ) +
                    "\n\t\t"
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
      _c("b-table", {
        staticStyle: { "text-align": "left" },
        attrs: {
          responsive: "sm",
          fields: _vm.fields,
          items: _vm.processedCapturedPicsList,
          "head-variant": _vm.hv,
          hover: true,
          "caption-top": true,
          "sticky-header": _vm.$screen.width <= 576 ? "200px" : "500px",
          bordered: "",
          small: "",
          "empty-text": _vm.FormMSG(3, "No files imported Yet"),
          "show-empty": "",
        },
        scopedSlots: _vm._u([
          {
            key: "head(actionSelected)",
            fn: function () {
              return [
                _vm.processedCapturedPicsList.length
                  ? _c("b-form-checkbox", {
                      attrs: { size: "lg" },
                      on: { change: _vm.handleInputSelectAll },
                      model: {
                        value: _vm.selectAll,
                        callback: function ($$v) {
                          _vm.selectAll = $$v
                        },
                        expression: "selectAll",
                      },
                    })
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "cell(actionSelected)",
            fn: function (data) {
              return [
                _c("b-form-checkbox", {
                  attrs: { size: "lg" },
                  model: {
                    value: data.item.ocrUseState,
                    callback: function ($$v) {
                      _vm.$set(data.item, "ocrUseState", $$v)
                    },
                    expression: "data.item.ocrUseState",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(data)",
            fn: function (data) {
              return [
                _c("div", { staticClass: "captured-item" }, [
                  _c("div", { staticClass: "preview-container" }, [
                    data.item.fileType && data.item.fileType.startsWith("image")
                      ? _c("img", { attrs: { src: data.item.data } })
                      : _c(
                          "div",
                          { staticClass: "file-icon" },
                          [
                            _c(_vm.getLucideIcon("FileText"), {
                              tag: "component",
                              attrs: { size: 20, "stroke-width": 2 },
                            }),
                          ],
                          1
                        ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "actions" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.isCroppable(data.item.data),
                              expression: "isCroppable(data.item.data)",
                            },
                          ],
                          staticClass: "text-dark",
                          attrs: { variant: "link", size: "sm" },
                          on: {
                            click: function ($event) {
                              return _vm.setImageForCrop(
                                data.item.data,
                                data.index
                              )
                            },
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("Crop"), {
                            tag: "component",
                            attrs: { size: 20, "stroke-width": 2 },
                          }),
                          _vm.$screen.width > 576
                            ? _c("span", { staticClass: "ml-2" }, [
                                _vm._v(_vm._s(_vm.FormMSG(6, "Crop"))),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "text-danger",
                          attrs: { size: "sm", variant: "link" },
                          on: {
                            click: function ($event) {
                              return _vm.removeCapturedPic(data.index)
                            },
                          },
                        },
                        [
                          _c(_vm.getLucideIcon("Trash"), {
                            tag: "component",
                            attrs: { size: 20, "stroke-width": 2 },
                          }),
                          _vm.$screen.width > 576
                            ? _c("span", { staticClass: "ml-2" }, [
                                _vm._v(_vm._s(_vm.FormMSG(7, "Remove"))),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }