var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "upload-capture-container--component-wrapper": !_vm.carouselMode,
      },
    },
    [
      _vm.previewMode === "card"
        ? _c(
            "div",
            { staticClass: "holder" },
            [
              !_vm.$isPwa()
                ? _c("PreviewImagesThumb", {
                    attrs: {
                      images: _vm.manager.states.editPicsList,
                      loading: _vm.isPwaCaptureLoading,
                      "carousel-mode": _vm.carouselMode,
                    },
                    on: {
                      "update:images": function ($event) {
                        return _vm.$set(
                          _vm.manager.states,
                          "editPicsList",
                          $event
                        )
                      },
                      "show-picture": _vm.showPicture,
                      "crop-picture": _vm.handleCropPictureFromList,
                      "delete-picture": _vm.handleDeletePicture,
                      "add-pic-click": _vm.openImagesModal,
                    },
                  })
                : _vm._e(),
              _vm.$isPwa()
                ? _c("PreviewImagesThumb", {
                    attrs: {
                      "hide-crop-option": "",
                      loading: _vm.isPwaCaptureLoading,
                      images: _vm.manager.states.editPicsList,
                      "carousel-mode": _vm.carouselMode,
                    },
                    on: {
                      "update:images": function ($event) {
                        return _vm.$set(
                          _vm.manager.states,
                          "editPicsList",
                          $event
                        )
                      },
                      "show-picture": _vm.showPicture,
                      "delete-picture": _vm.handleDeletePicture,
                      "add-pic-click": _vm.clickAddByPicturePwa,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.previewMode === "button"
        ? _c("div", { staticClass: "d-flex row" }, [
            _vm.manager.states.editPicsList.length > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "col-md-6 col-sm-12 pb-2 d-flex justify-content-end",
                    style: `display: ${
                      !_vm.dispatchEventAddAction ? "flex" : "none"
                    } !important;`,
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass:
                          "d-flex flex-row justify-content-center align-items-center",
                        class: `${
                          !_vm.$isPwa() && _vm.$screen.width > 576
                            ? "w-35"
                            : "w-100"
                        }`,
                        attrs: { variant: "outline-secondary" },
                        on: {
                          click: function ($event) {
                            return _vm.showPicture(0)
                          },
                        },
                      },
                      [
                        _c(_vm.getLucideIcon(_vm.ICONS.PICTURE.name), {
                          tag: "component",
                          staticClass: "mr-2",
                          attrs: { size: 20 },
                        }),
                        _c("div", { staticStyle: { "margin-top": "1px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.rendPictureLabel(
                                _vm.manager.states.editPicsList.length
                              )
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.showAddBtn
              ? _c(
                  "div",
                  {
                    staticClass: "col-sm-12 push_t_20_min600",
                    class: [
                      `${
                        _vm.manager.states.editPicsList.length > 0
                          ? "col-md-6"
                          : "col-md-12"
                      }`,
                      `${
                        _vm.manager.states.editPicsList.length === 0
                          ? "text-center"
                          : ""
                      }`,
                    ],
                    style: `display: ${
                      !_vm.dispatchEventAddAction ? "block" : "none"
                    } !important;`,
                  },
                  [
                    !_vm.$isPwa()
                      ? _c(
                          "b-button",
                          {
                            ref: "add-images-none-pwa",
                            class: `${
                              !_vm.$isPwa() && _vm.$screen.width > 576
                                ? "w-25"
                                : "w-100"
                            }`,
                            attrs: {
                              variant: "outline-success",
                              disabled:
                                _vm.addImageDisabled ||
                                _vm.waitBeforeAddImage ||
                                _vm.isPwaCaptureLoading,
                            },
                            on: { click: _vm.openImagesModal },
                          },
                          [
                            _vm.isPwaCaptureLoading || _vm.waitBeforeAddImage
                              ? _c("b-spinner", {
                                  attrs: { variant: "light", small: "" },
                                })
                              : _vm._e(),
                            _vm._v(
                              "\n\t\t\t\t" +
                                _vm._s(
                                  _vm.waitBeforeAddImage
                                    ? _vm.FormMSG(
                                        876876,
                                        "Wait before add image(s)"
                                      )
                                    : _vm.FormMSG(1, "Add Images")
                                ) +
                                "\n\t\t\t"
                            ),
                          ],
                          1
                        )
                      : _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-12" },
                            [
                              _c(
                                "b-button",
                                {
                                  ref: "add-images-pwa",
                                  class: `${!_vm.$isPwa() ? "w-50" : "w-100"}`,
                                  attrs: {
                                    disabled:
                                      _vm.addImageDisabled ||
                                      _vm.isPwaCaptureLoading,
                                    variant: "outline-success",
                                  },
                                  on: { click: _vm.clickAddByPicturePwa },
                                },
                                [
                                  _vm.isPwaCaptureLoading
                                    ? _c("b-spinner", {
                                        attrs: { variant: "light", small: "" },
                                      })
                                    : _vm._e(),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t" +
                                      _vm._s(_vm.FormMSG(1, "Add Images")) +
                                      "\n\t\t\t\t\t"
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm.canUploadPdf
                            ? _c(
                                "div",
                                {
                                  ref: "add-pdf-pwa",
                                  staticClass: "col-12 mt-2",
                                },
                                [
                                  _c("b-form-file", {
                                    ref: "pdfFileId",
                                    attrs: {
                                      "browse-text": _vm.FormMSG(
                                        113,
                                        "Browse PDF"
                                      ),
                                      name: "test",
                                      id: "docFileBrowse",
                                      accept: ".pdf",
                                      placeholder: _vm.FormMSG(
                                        156,
                                        "Choose a file..."
                                      ),
                                      disabled: _vm.isPdfFilePickLoading,
                                    },
                                    on: { change: _vm.handlePickPdfFile },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ]),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.previewMode === "table"
        ? _c(
            "div",
            { staticClass: "holder" },
            [
              _c("b-table", {
                ref: "tableImages",
                staticClass: "img-list-table",
                attrs: {
                  hover: "",
                  responsive: "sm",
                  selectable: "",
                  "selected-variant": "primary",
                  "select-mode": "single",
                  "sticky-header": "400px",
                  bordered: "",
                  small: "",
                  "head-variant": "dark",
                  fields: _vm.imgFields,
                  items: _vm.manager.states.editPicsList,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(filename)",
                      fn: function (data) {
                        return [
                          _c("div", { staticClass: "rich-attachment" }, [
                            _c("img", {
                              attrs: { src: data.item.src, alt: data.item.xid },
                            }),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "cell(actions)",
                      fn: function (data) {
                        return [
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "primary", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.showPicture(data.index)
                                },
                              },
                            },
                            [_c("i", { staticClass: "icon-eye" })]
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleCropPicture(
                                    data.item.xid,
                                    data.index
                                  )
                                },
                              },
                            },
                            [_c("ScissorsIconSVG")],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger", size: "sm" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeletePicture(data.item.xid)
                                },
                              },
                            },
                            [_c("i", { staticClass: "icon-trash" })]
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  3949225697
                ),
              }),
              _vm.showAddBtn
                ? _c(
                    "div",
                    { staticClass: "full push_t_20 pull_end" },
                    [
                      !_vm.$isPwa()
                        ? _c(
                            "b-button",
                            {
                              ref: "add-images-none-pwa",
                              staticClass: "float-right",
                              class: `${!_vm.$isPwa() ? "w-50" : "w-100"}`,
                              style: `display: ${
                                !_vm.dispatchEventAddAction ? "block" : "none"
                              } !important;`,
                              attrs: {
                                variant: "outline-success",
                                disabled:
                                  _vm.addImageDisabled ||
                                  _vm.isPwaCaptureLoading,
                              },
                              on: { click: _vm.openImagesModal },
                            },
                            [
                              _vm.isPwaCaptureLoading
                                ? _c("b-spinner", {
                                    attrs: { variant: "light", small: "" },
                                  })
                                : _vm._e(),
                              _vm._v(
                                "\n\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1, "Add Images")) +
                                  "\n\t\t\t"
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$isPwa()
                        ? _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    class: `${
                                      !_vm.$isPwa() ? "w-50" : "w-100"
                                    }`,
                                    attrs: { variant: "outline-success" },
                                    on: { click: _vm.clickAddByPicturePwa },
                                  },
                                  [
                                    _vm.isPwaCaptureLoading
                                      ? _c("b-spinner", {
                                          attrs: {
                                            variant: "light",
                                            small: "",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(1, "Add Images")) +
                                        "\n\t\t\t\t\t"
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm.canUploadPdf
                              ? _c(
                                  "div",
                                  { staticClass: "col-12 mt-2" },
                                  [
                                    _c("b-form-file", {
                                      ref: "pdfFileId",
                                      attrs: {
                                        "browse-text": _vm.FormMSG(
                                          113,
                                          "Browse PDF"
                                        ),
                                        name: "test",
                                        id: "docFileBrowse",
                                        accept: ".pdf",
                                        placeholder: _vm.FormMSG(
                                          156,
                                          "Choose a file..."
                                        ),
                                        disabled: _vm.isPdfFilePickLoading,
                                      },
                                      on: { change: _vm.handlePickPdfFile },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("CaptureModal", {
        ref: "captureModalItem",
        attrs: {
          "generate-dynamic-parents-id-per-doc":
            _vm.generateDynamicParentsIdPerDoc,
          manager: _vm.manager,
          "for-new-expense": _vm.forNewExpense,
          "for-multiple-doc": _vm.forMultipleDoc,
          "for-splitting-tva": _vm.forSplittingTva,
        },
        on: {
          change: (e) => _vm.$emit("change", e),
          cancel: function ($event) {
            return _vm.$emit("capture-modal:cancel", true)
          },
          close: function ($event) {
            return _vm.$emit("capture-modal:close", true)
          },
        },
        model: {
          value: _vm.manager.states.isCaptureModalOpen,
          callback: function ($$v) {
            _vm.$set(_vm.manager.states, "isCaptureModalOpen", $$v)
          },
          expression: "manager.states.isCaptureModalOpen",
        },
      }),
      _c(
        "CropCaptureModal",
        _vm._b(
          {
            key: _vm.manager.states.cropCaptureModalKey,
            ref: "myCropPicture",
            attrs: {
              "base-url-img": _vm.manager.states.imageForCrop,
              props: (_vm.testTime = true),
            },
            on: {
              "update:baseUrlImg": function ($event) {
                return _vm.$set(_vm.manager.states, "imageForCrop", $event)
              },
              "update:base-url-img": function ($event) {
                return _vm.$set(_vm.manager.states, "imageForCrop", $event)
              },
              change: _vm.handleCropChange,
              cropped: _vm.handleCropCropped,
              close: _vm.handleCropModalClose,
            },
            model: {
              value: _vm.isCropCaptureModalOpen,
              callback: function ($$v) {
                _vm.isCropCaptureModalOpen = $$v
              },
              expression: "isCropCaptureModalOpen",
            },
          },
          "CropCaptureModal",
          _vm.$props,
          false
        )
      ),
      _c("delete-image-modal", {
        on: {
          "delete-image-modal:hide": _vm.hideImageDeleteWarning,
          "delete-image-modal:confirm": _vm.confirmImageDelete,
        },
        model: {
          value: _vm.showDeleteImageWarning,
          callback: function ($$v) {
            _vm.showDeleteImageWarning = $$v
          },
          expression: "showDeleteImageWarning",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }