var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.carouselMode
      ? _c(
          "div",
          {
            staticClass:
              "preview-images-thumb--imageshowroom--component-wrapper",
          },
          [
            _vm._l(_vm.images, function (img, i) {
              return _c(
                "div",
                { key: i, staticClass: "asset-preview-picture" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "rich-attachment",
                      on: {
                        click: function ($event) {
                          return _vm.showPicture(i)
                        },
                      },
                    },
                    [_c("img", { attrs: { src: img.thumb, alt: img.xid } })]
                  ),
                  _c("div", { staticClass: "veil" }),
                  _c(
                    "b-dropdown",
                    {
                      attrs: {
                        size: "lg",
                        variant: "link",
                        "toggle-class": "text-decoration-none",
                        "no-caret": "",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "button-content",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "icon-arrow-down" }),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      !_vm.hideCropOption
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleCropPicture(img.xid, i)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(_vm.FormMSG(9992323, "Crop")) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      !_vm.hideDeleteOption
                        ? _c(
                            "b-dropdown-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.handleDeletePicture(img.xid)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t" +
                                    _vm._s(
                                      _vm.FormMSG(2323, "Delete picture")
                                    ) +
                                    "\n\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            _c(
              "span",
              {
                staticClass: "add-attachment-btn",
                on: { click: _vm.clickAddPicture },
              },
              [
                _vm.loading
                  ? _c("b-spinner", { attrs: { variant: "light", small: "" } })
                  : _c("i", { staticClass: "icon-plus" }),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
    _vm.carouselMode
      ? _c(
          "div",
          [
            _vm.$screen.width > 576
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c("Carousel", {
                          attrs: {
                            value: _vm.carouselImages,
                            numVisible: _vm.isNumVisible,
                            numScroll: _vm.isNumScroll,
                            responsiveOptions: _vm.isReponsiveOptions,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "item",
                                fn: function (slotProps) {
                                  return [
                                    slotProps.data.isFirst
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "add-img mt-2",
                                            on: { click: _vm.clickAddPicture },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "center-content" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn-transparent",
                                                    attrs: { type: "button" },
                                                  },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        "PlusCircle"
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          size: 20,
                                                          color: "#00A09C",
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _c(
                                          "div",
                                          {
                                            staticClass:
                                              "border-1 surface-border m-2 border-round",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                on: {
                                                  mouseenter: function (
                                                    $event
                                                  ) {
                                                    return _vm.handleMouseEnter(
                                                      slotProps.data,
                                                      slotProps.index - 1
                                                    )
                                                  },
                                                  mouseleave: function (
                                                    $event
                                                  ) {
                                                    return _vm.handleMouseLeave(
                                                      slotProps.data,
                                                      slotProps.index - 1
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column justify-content-center position-absolute ml-1 h-75",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              slotProps.data
                                                                .hoverAction,
                                                            expression:
                                                              "slotProps.data.hoverAction",
                                                          },
                                                          {
                                                            name: "b-tooltip",
                                                            rawName:
                                                              "v-b-tooltip.hover",
                                                            modifiers: {
                                                              hover: true,
                                                            },
                                                          },
                                                        ],
                                                        staticClass:
                                                          "bg-primary text-white mb-3",
                                                        staticStyle: {
                                                          width: "93px",
                                                        },
                                                        attrs: {
                                                          title:
                                                            _vm.filenames[
                                                              slotProps.index -
                                                                1
                                                            ].name,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.subSentence(
                                                                _vm.filenames[
                                                                  slotProps.index -
                                                                    1
                                                                ].name
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        class: {
                                                          "d-flex align-items-center justify-content-center":
                                                            slotProps.data
                                                              .hoverAction,
                                                          "d-none":
                                                            !slotProps.data
                                                              .hoverAction,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.showPicture(
                                                                  slotProps.index -
                                                                    1
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "Eye"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 22,
                                                                  "stroke-width": 1.5,
                                                                  color:
                                                                    "#00A09C",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn-transparent text-color-burning-tomato",
                                                            attrs: {
                                                              type: "button",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.handleDeletePicture(
                                                                  slotProps.data
                                                                    .xid
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              _vm.getLucideIcon(
                                                                "Trash2"
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  size: 22,
                                                                  "stroke-width": 1.5,
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _c("img", {
                                                  staticClass:
                                                    "location-slide-img",
                                                  attrs: {
                                                    src: slotProps.data.thumb,
                                                    alt: slotProps.data.xid,
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3262406840
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$screen.width <= 576 && _vm.carouselImages.length > 0
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "mb-3", attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              block: "",
                              size: "md",
                              variant: "outline-primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.showPicture(0)
                              },
                            },
                          },
                          [
                            _c(_vm.getLucideIcon("FileImage"), {
                              tag: "component",
                              attrs: { size: 16 },
                            }),
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(
                                  `${_vm.FormMSG(90, "View")} ${
                                    _vm.carouselImages.length
                                  } ${_vm.FormMSG(91, "pictures")}`
                                ) +
                                "\n\t\t\t\t"
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.$screen.width <= 576
              ? _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { attrs: { cols: "12" } },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              block: "",
                              size: "md",
                              variant: "outline-success",
                            },
                            on: { click: _vm.clickAddPicture },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(79, "Add pictures")) +
                                "\n\t\t\t\t"
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }