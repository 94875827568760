<template>
	<div :class="{ 'upload-capture-container--component-wrapper': !carouselMode }">
		<div v-if="previewMode === 'card'" class="holder">
			<PreviewImagesThumb
				v-if="!$isPwa()"
				:images.sync="manager.states.editPicsList"
				:loading="isPwaCaptureLoading"
				:carousel-mode="carouselMode"
				@show-picture="showPicture"
				@crop-picture="handleCropPictureFromList"
				@delete-picture="handleDeletePicture"
				@add-pic-click="openImagesModal"
			/>
			<PreviewImagesThumb
				v-if="$isPwa()"
				hide-crop-option
				:loading="isPwaCaptureLoading"
				:images.sync="manager.states.editPicsList"
				:carousel-mode="carouselMode"
				@show-picture="showPicture"
				@delete-picture="handleDeletePicture"
				@add-pic-click="clickAddByPicturePwa"
			/>
		</div>
		<div v-if="previewMode === 'button'" class="d-flex row">
			<div
				v-if="manager.states.editPicsList.length > 0"
				class="col-md-6 col-sm-12 pb-2 d-flex justify-content-end"
				:style="`display: ${!dispatchEventAddAction ? 'flex' : 'none'} !important;`"
			>
				<b-button
					variant="outline-secondary"
					class="d-flex flex-row justify-content-center align-items-center"
					:class="`${!$isPwa() && $screen.width > 576 ? 'w-35' : 'w-100'}`"
					@click="showPicture(0)"
				>
					<component :is="getLucideIcon(ICONS.PICTURE.name)" :size="20" class="mr-2" />
					<div style="margin-top: 1px">{{ rendPictureLabel(manager.states.editPicsList.length) }}</div>
				</b-button>
			</div>
			<div
				v-if="showAddBtn"
				:style="`display: ${!dispatchEventAddAction ? 'block' : 'none'} !important;`"
				class="col-sm-12 push_t_20_min600"
				:class="[
					`${manager.states.editPicsList.length > 0 ? 'col-md-6' : 'col-md-12'}`,
					`${manager.states.editPicsList.length === 0 ? 'text-center' : ''}`
				]"
			>
				<b-button
					ref="add-images-none-pwa"
					v-if="!$isPwa()"
					variant="outline-success"
					:disabled="addImageDisabled || waitBeforeAddImage || isPwaCaptureLoading"
					:class="`${!$isPwa() && $screen.width > 576 ? 'w-25' : 'w-100'}`"
					@click="openImagesModal"
				>
					<b-spinner v-if="isPwaCaptureLoading || waitBeforeAddImage" variant="light" small />
					{{ waitBeforeAddImage ? FormMSG(876876, 'Wait before add image(s)') : FormMSG(1, 'Add Images') }}
				</b-button>
				<div v-else class="row">
					<div class="col-12">
						<b-button
							ref="add-images-pwa"
							:class="`${!$isPwa() ? 'w-50' : 'w-100'}`"
							:disabled="addImageDisabled || isPwaCaptureLoading"
							variant="outline-success"
							@click="clickAddByPicturePwa"
						>
							<b-spinner v-if="isPwaCaptureLoading" variant="light" small />
							{{ FormMSG(1, 'Add Images') }}
						</b-button>
					</div>
					<div ref="add-pdf-pwa" v-if="canUploadPdf" class="col-12 mt-2">
						<b-form-file
							:browse-text="FormMSG(113, 'Browse PDF')"
							name="test"
							id="docFileBrowse"
							accept=".pdf"
							ref="pdfFileId"
							@change="handlePickPdfFile"
							:placeholder="FormMSG(156, 'Choose a file...')"
							:disabled="isPdfFilePickLoading"
						/>
					</div>
				</div>
			</div>
		</div>
		<div v-if="previewMode === 'table'" class="holder">
			<b-table
				ref="tableImages"
				class="img-list-table"
				hover
				responsive="sm"
				selectable
				selected-variant="primary"
				select-mode="single"
				sticky-header="400px"
				bordered
				small
				head-variant="dark"
				:fields="imgFields"
				:items="manager.states.editPicsList"
			>
				<template #cell(filename)="data">
					<div class="rich-attachment">
						<img :src="data.item.src" :alt="data.item.xid" />
					</div>
				</template>
				<template #cell(actions)="data">
					<b-button variant="primary" class="mr-2" size="sm" @click="showPicture(data.index)">
						<i class="icon-eye" />
					</b-button>
					<b-button size="sm" class="mr-2" @click="handleCropPicture(data.item.xid, data.index)">
						<ScissorsIconSVG />
					</b-button>
					<b-button variant="danger" size="sm" @click="handleDeletePicture(data.item.xid)">
						<i class="icon-trash" />
					</b-button>
				</template>
			</b-table>
			<div v-if="showAddBtn" class="full push_t_20 pull_end">
				<b-button
					ref="add-images-none-pwa"
					v-if="!$isPwa()"
					variant="outline-success"
					class="float-right"
					:disabled="addImageDisabled || isPwaCaptureLoading"
					:class="`${!$isPwa() ? 'w-50' : 'w-100'}`"
					@click="openImagesModal"
					:style="`display: ${!dispatchEventAddAction ? 'block' : 'none'} !important;`"
				>
					<b-spinner v-if="isPwaCaptureLoading" variant="light" small />
					{{ FormMSG(1, 'Add Images') }}
				</b-button>
				<div v-if="$isPwa()" class="row">
					<div class="col">
						<b-button :class="`${!$isPwa() ? 'w-50' : 'w-100'}`" variant="outline-success" @click="clickAddByPicturePwa">
							<b-spinner v-if="isPwaCaptureLoading" variant="light" small />
							{{ FormMSG(1, 'Add Images') }}
						</b-button>
					</div>
					<div v-if="canUploadPdf" class="col-12 mt-2">
						<b-form-file
							:browse-text="FormMSG(113, 'Browse PDF')"
							name="test"
							id="docFileBrowse"
							accept=".pdf"
							ref="pdfFileId"
							@change="handlePickPdfFile"
							:placeholder="FormMSG(156, 'Choose a file...')"
							:disabled="isPdfFilePickLoading"
						/>
					</div>
				</div>
			</div>
		</div>
		<CaptureModal
			v-model="manager.states.isCaptureModalOpen"
			ref="captureModalItem"
			:generate-dynamic-parents-id-per-doc="generateDynamicParentsIdPerDoc"
			:manager="manager"
			:for-new-expense="forNewExpense"
			:for-multiple-doc="forMultipleDoc"
			:for-splitting-tva="forSplittingTva"
			@change="(e) => $emit('change', e)"
			@cancel="$emit('capture-modal:cancel', true)"
			@close="$emit('capture-modal:close', true)"
		/>
		<CropCaptureModal
			ref="myCropPicture"
			v-bind="$props"
			v-model="isCropCaptureModalOpen"
			:key="manager.states.cropCaptureModalKey"
			:base-url-img.sync="manager.states.imageForCrop"
			:props="(testTime = true)"
			@change="handleCropChange"
			@cropped="handleCropCropped"
			@close="handleCropModalClose"
		/>
		<!-- Warning on delete images -->
		<delete-image-modal
			v-model="showDeleteImageWarning"
			@delete-image-modal:hide="hideImageDeleteWarning"
			@delete-image-modal:confirm="confirmImageDelete"
		></delete-image-modal>
		<!-- Warning on delete images -->
	</div>
</template>

<script>
import { Camera, CameraResultType } from '@capacitor/camera';
import CapturesManager from '@/components/Packages/Captures/capture.manager';
import mapProps from '@/shared/vuePropsMapper';
import { isNil, isObj, makeID, isBase64, generateSecureId } from '@/shared/utils';
import { getFileExtension } from '@/shared/helpers';
import { reScalingImgWithCanvas } from '@/components/Packages/Captures/capture.utils';
import { store } from '@/store';
import { mapFilters } from '~filters';
import languageMessages from '@/mixins/languageMessages';
import CaptureModal from '@/components/Packages/Captures/components/CaptureModal';
import CropCaptureModal from '@/components/Packages/Captures/components/CropCaptureModal';
import PreviewImagesThumb from '@/components/Packages/FIlesPreviewer/components/PreviewImagesThumb';
import ScissorsIconSVG from '@/components/icons/scissors';
import globalMixin from '@/mixins/global.mixin';
import DeleteImageModal from '@/components/Modals/DeleteImageModal';
import _ from 'lodash';
import { isFileSupported } from '@/utils/isFileSupported';

const authorizedPreviewsMode = ['table', 'card', 'button', 'carousel'];

export default {
	name: 'PackagesCaptureComponentsMain',
	components: { ScissorsIconSVG, CaptureModal, CropCaptureModal, PreviewImagesThumb, DeleteImageModal },
	mixins: [languageMessages, globalMixin],
	props: {
		...mapProps(['parentType', 'parentSubType'], {
			type: String,
			required: false,
			default: null
		}),
		...mapProps(['multiple', 'canUploadPdf', 'carouselMode', 'addImageDisabled', 'waitBeforeAddImage'], {
			type: Boolean,
			required: false,
			default: false
		}),
		previewMode: {
			type: String,
			required: false,
			default: 'card',
			validator: (v) => authorizedPreviewsMode.includes(v)
		},
		previewModalOptions: {
			type: Object,
			required: false,
			default: () => {}
		},
		pictures: {
			type: [Array, String],
			required: false,
			default: null
		},
		noWebcam: {
			type: Boolean,
			required: false,
			default: true
		},
		parentId: {
			type: [Number, String],
			required: false,
			default: null
		},
		showAddBtn: {
			type: Boolean,
			default: true,
			required: false
		},
		/**
		 * function for generating a key for each upload file
		 * /!\/!\/!\ this function should be a Promise /!\/!\/!\
		 */
		generateDynamicParentsIdPerDoc: {
			type: Function,
			required: false,
			default: null
		},
		dispatchEventAddAction: {
			type: Boolean,
			default: false,
			required: false
		},
		forNewExpense: {
			type: Boolean,
			default: false,
			required: false
		},
		forMultipleDoc: {
			type: Boolean,
			default: false,
			required: false
		},
		forSplittingTva: {
			type: Boolean,
			default: false,
			required: false
		}
	},
	data() {
		return {
			manager: new CapturesManager(this, {
				multiple: this.multiple
			}),
			isShowRoomOpen: false,
			isPwaCaptureLoading: false,
			isPdfFilePickLoading: false,
			uploadFileCount: 1,
			showDeleteImageWarning: false,
			imageToDelete: '',
			fileNameGenerated: null
		};
	},
	computed: {
		isCropCaptureModalOpen: {
			/**
			 * @return {Boolean}
			 */
			/**
			 * @return {Boolean}
			 */
			get() {
				return this.manager.states.isCropCaptureModalOpen;
			},
			/**
			 * @param {Boolean} status
			 */
			/**
			 * @param {Boolean} status
			 */
			set(status) {
				if (!status) {
					this.manager.dispatch('setImageForCrop', { index: 0, img: '', list: [] });
				}
				this.manager.dispatch('toggleCropCaptureModalOpen', status);
			}
		},
		/**
		 * @return {Object }
		 */
		captureOptions() {
			return this.manager.states.options;
		},
		/**
		 * @return {Array}
		 */
		imgFields() {
			return [
				{
					key: 'filename',
					label: this.FormMSG(678, 'Picture'),
					sortable: true
				},
				{
					key: 'actions',
					label: this.FormMSG(98, 'Actions'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		}
	},
	watch: {
		pictures: {
			/**
			 * @param {Array|String} payload
			 */
			handler(payload) {
				if (!_.isNil(payload)) this.setEditPictures([...payload].reverse());
			},
			immediate: true,
			deep: true
		},
		parentId: {
			/**
			 * @param {String|Number} val
			 */
			handler(val) {
				this.manager.dispatch('setOptions', { parentId: val });
			},
			immediate: true
		},
		'manager.states.editPicsList': {
			/**
			 * @param {Array} list
			 */
			handler(list) {
				const imgs = list.map((i) => i.xid);
				this.$bus.$emit('update-images-list-showroom', imgs);
			},
			deep: true
		}
	},
	created() {
		this.manager.dispatch('setOptions', {
			previewMode: this.previewMode,
			parentId: this.parentId,
			parentType: this.parentType,
			parentSubType: this.parentSubType
		});
		// this.setEditPictures(this.pictures);
		// this.manager.dispatch('initImgsListPayload');

		this.$bus.$on('open-crop-modal-module', ({ img, index }) => {
			this.isShowRoomOpen = true;
			this.handleCropPicture(img, index);
		});

		this.$bus.$on('set-xid-delete-modal-module', (xid) => {
			this.isShowRoomOpen = true;
			this.handleDeletePicture(xid);
		});

		this.$previewerEventListener('capture-expense-previewer-elem', (e) => {
			const {
				on_documents_previewer_event__: { type }
			} = e;
			if (type === 'close') {
				this.$emit('capture-modal:close-previewer');
			}
		});
	},
	methods: {
		...mapFilters(['pluralize']),
		openImagesModal() {
			this.manager.dispatch('toggleCaptureModal');
		},
		/**
		 * @param {String|Array} payload
		 */
		setEditPictures(payload) {
			this.manager.dispatch('setEditPictures', payload);
			// console.log(this.manager);
		},
		async clickAddByPicturePwa() {
			this.setPwaCaptureLoadingStatus(true);
			try {
				const photo = await Camera.getPhoto({
					resultType: CameraResultType.Uri,
					allowEditing: false,
					quality: 100
				});

				// const blob = await fetch(photo.webPath).then((r) => r.blob());
				const blob = await reScalingImgWithCanvas(photo.webPath);
				const formData = await this.prepareFormData(blob);
				await this.uploadFormData(formData);
			} catch (e) {
				console.error({ e });
				this.isPwaCaptureLoading = false;
				if (e.errorMessage !== 'User cancelled photos app') {
					this.manager.emit('upload-image:error', e);
				}
			}

			// const blob = await fetch(photo.webPath).then((r) => r.blob());
			// const compressedBlob = await getCompressedImage(blob);
			// const formData = await this.prepareFormData(compressedBlob);
			// const formData = await this.prepareFormData(blob);
			// this.handleCropPicture(blob, 0);

			// work in app/535
			// const photo = await Camera.getPhoto({
			//   resultType: CameraResultType.Uri,
			//   allowEditing: false,
			//   quality: 100
			// });
			// this.handleCropPicture(photo.webPath, 0);

			this.setPwaCaptureLoadingStatus(false);
		},
		/**
		 * @param {Event} $event
		 */
		async handlePickPdfFile($event) {
			this.isPdfFilePickLoading = true;
			this.setPwaCaptureLoadingStatus(true);
			const pdfFile = $event.target.files[0];
			if (isFileSupported(pdfFile, ['.pdf'])) {
				const formData = await this.prepareFormData(pdfFile);
				await this.uploadFormData(formData);
				this.isPdfFilePickLoading = false;
				this.setPwaCaptureLoadingStatus(false);
			} else {
				this.createToastForMobile(
					this.FormMSG(117, 'Error'),
					pdfFile.name + ' : ' + this.FormMSG(116, 'The file format is not supported. Please upload a supported file.'),
					'',
					'danger'
				);
				this.$refs['pdfFileId'].reset();
				this.isPdfFilePickLoading = false;
				this.setPwaCaptureLoadingStatus(false);
			}
		},
		//  convertedToFile(imgData) {
		//    if (!isBase64(imgData)) return imgData;
		//    const base64Data = imgData.split(';base64,');
		// const ext = getExtensionFromBase64(imgData);
		// return b64toFile(base64Data[1], 'upload-file', ext);
		//  },
		async prepareFormData(file) {
			let formData = new FormData();
			// const _f = this.convertedToFile(file);
			// formData.append('uploadimage', _f);
			const fileData = await provideFileDataToUpload(file);
			formData.append('uploadimage', fileData.file);

			const { parentType, parentSubType, parentId } = this.captureOptions;
			if (!isNil(parentType)) formData.append('parentType', parentType);
			if (!isNil(parentSubType)) formData.append('parentSubType', parentSubType);

			const generatedParentId =
				this.uploadFileCount > 1 && !isNil(this.generateDynamicParentsIdPerDoc) ? await this.generateDynamicParentsIdPerDoc() : parentId;

			if (!isNil(parentId)) formData.append('parentId', generatedParentId);

			formData.append('fileName', fileData.name);

			this.uploadFileCount = this.uploadFileCount + 1;

			return formData;
		},
		async uploadFormData(formData) {
			try {
				const pic = await this.$axios.$post('upload', formData, {
					headers: {
						Authorization: `Bearer ${store.state.token}`,
						'content-type': 'multipart/form-data'
					}
				});

				await this.manager.dispatch('setImgsListPayload', pic);
				this.manager.emit('change', this.manager.states.imgsListPayload);
				// console.log('reach');
				// this.manager.dispatch('clearImgsList');
			} catch (e) {
				console.error({ e });
				this.manager.emit('upload-image:error', e);
			}
		},
		/**
		 * @param {Boolean} status
		 */
		setPwaCaptureLoadingStatus(status) {
			this.isPwaCaptureLoading = status;
			this.$emit('upload-image:loading', status);
		},
		/**
		 * @param {String} xid
		 */
		handleDeletePicture(xid) {
			if (isNil(xid)) return;
			this.imageToDelete = xid;
			this.showDeleteImageWarning = true;

			// const txt = this.FormMSG(55554, 'Please confirm that you want delete this image');

			// this.$bvModal
			// 	.msgBoxConfirm(txt, {
			// 		title: this.FormMSG(55553, 'Please Confirm'),
			// 		size: 'sm',
			// 		buttonSize: 'sm',
			// 		okVariant: 'danger',
			// 		okTitle: this.FormMSG(55551, 'YES'),
			// 		cancelTitle: this.FormMSG(55552, 'NO'),
			// 		footerClass: 'p-2',
			// 		hideHeaderClose: false,
			// 		centered: false,
			// 		style: 'z-index: 9999'
			// 	})
			// 	.then((s) => {
			// 		if (!s) return;
			// 		const list = this.pictures;
			// 		const index = list.findIndex((l) => l === xid);
			// 		if (~index) {
			// 			list.splice(index, 1);
			// 			this.setEditPictures(list);
			// 			this.$emit('change', list);
			// 		}
			// 	})
			// 	.catch((err) => console.error(err));
		},
		confirmImageDelete() {
			const list = this.pictures;
			const index = list.findIndex((l) => l === this.imageToDelete);
			if (~index) {
				list.splice(index, 1);
				this.setEditPictures(list);
				this.$emit('change', list);
			}
			this.hideImageDeleteWarning();
		},
		hideImageDeleteWarning() {
			this.imageToDelete = '';
			this.showDeleteImageWarning = false;
		},
		handleCropPictureFromList({ xid, index }) {
			this.handleCropPicture(xid, index);
		},
		/**
		 * @param {String} img
		 * @param {Number} index
		 */
		handleCropPicture(img, index) {
			const opt = {
				img: process.env.VUE_APP_PATH_IMG_URL + img,
				index,
				type: 'cropReplacement'
			};
			this.manager.dispatch('setImageForCrop', opt);
			store.state.imageToCrop = img;

			this.isCropCaptureModalOpen = true;
		},
		/**
		 * @param {Number} index
		 */
		showPicture(index) {
			// console.log(this.manager.states.imgsListPayload);
			const images = this.manager.states.editPicsList.map((img) => {
				return isObj(img)
					? { ...img, ext: getFileExtension(img.xid) }
					: {
							xid: img,
							src: `${process.env.VUE_APP_GQL}/images/${img}`,
							thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
							ext: getFileExtension(img)
					  };
			});
			this.$previewImages({
				name: 'capture-expense-previewer-elem',
				images: [...images].reverse(),
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					hideCropButton: false,
					hideDeleteButton: false,
					...this.previewModalOptions
				}
			});
		},
		/**
		 * @param {Object} cropOptions
		 */
		async handleCropChange(cropOptions) {
			if (!this.$isPwa()) {
				if (cropOptions.type !== 'cropReplacement') return;
				this.manager.dispatch('initImgsListPayload');
				const list = this.manager.states.imgsListPayload;
				const originalIndex = list.length - 1 - cropOptions.index; // because the cropOptions.index is a reversed index.
				if (!isNil(list[originalIndex])) list.splice(originalIndex, 1);
				await this.$refs.captureModalItem.saveImage(cropOptions.img);
				this.manager.emit('change', list);
			} else {
				const formData = await this.prepareFormData(cropOptions.img);
				await this.uploadFormData(formData);
			}
			this.manager.dispatch('updateCropCaptureModalKey');
			this.manager.dispatch('clearImgsList');
		},
		handleCropCropped(opt) {
			this.manager.dispatch('replaceIndexCapturedPicsList', {
				img: opt.img,
				index: opt.index
			});
		},
		/**
		 * @param {Number} count
		 * @return {String}
		 */
		rendPictureLabel(count) {
			const _t = this.FormMSG;
			const p = this.pluralize('picture', count);
			return `${_t(43341, 'view')} ${count} ${_t(43342, p)}`;
		},
		handleCropModalClose() {
			this.manager.dispatch('toggleCropCaptureModalOpen', false);
			this.manager.dispatch('updateCropCaptureModalKey');
		},
		generateFilename(extensionFile) {
			return generateSecureId(`filename-id-${makeID(8)}`).substring(0, 24) + '.' + extensionFile;
		}
	}
};
</script>

<style lang="scss" scoped>
.upload-capture-container--component-wrapper {
	width: 100%;
	height: auto;

	.holder {
		width: 100%;
		align-items: center;
		display: flex;
		flex-wrap: wrap;

		.img-list-table {
			width: 100%;
			.rich-attachment {
				max-height: 40px;
				img {
					max-height: 40px;
				}
			}
		}
	}
}
</style>
