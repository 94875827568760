var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "get_desktop_img_wrapper" },
    [
      _c(
        "b-form-group",
        [
          _c("b-form-file", {
            ref: "desktopImgFileId",
            attrs: {
              id: "file-default",
              multiple: "",
              "browse-text": _vm.FormMSG(113, "Browse"),
              placeholder: _vm.FormMSG(114, "Choose a file from your computer"),
              accept: _vm.rendAccept,
            },
            on: { change: _vm.onFileSelected },
          }),
          _vm.$has(_vm.unsupportedMessage)
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(_vm._s(_vm.unsupportedMessage)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }